import React, { useEffect } from 'react';
import img1 from './Images/Img1.jpeg';
import img2 from './Images/Img2.jpeg';
import img3 from './Images/Img3.jpeg';
import img4 from './Images/3.jpg';
import img5 from './Images/main.jpg';

function ImageSlider() {
    useEffect(() => {
        const carousel = document.getElementById('carouselExampleAutoplaying');
        const carouselInterval = setInterval(() => {
            const nextButton = carousel.querySelector('.carousel-control-next');
            nextButton.click();
        }, 3000);

        return () => {
            clearInterval(carouselInterval);
        };
    }, []);

    return (
        <div>
            <style>{`
                .carousel-item {
                    padding-left: 20%;
                    padding-right: 20%;
                    width: 100%;
                    height: 500px;
                }
                .carousel-control-prev-icon,
                .carousel-control-next-icon {
                    background-color: transparent; /* Remove background */
                    filter: invert(38%) sepia(80%) saturate(1341%) hue-rotate(191deg) brightness(101%) contrast(102%); /* Apply filter to change icon color */
                }
                    .carousel-inner{
                    padding-top:5%;
                    }
                }
            `}</style>
            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                <br />
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="4" aria-label="Slide 5"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={img2} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src={img1} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src={img3} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src={img4} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src={img5} className="d-block w-100" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
}

export default ImageSlider;
