import React from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaMapMarkerAlt, FaPhone, FaArrowRight } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import './contactUs.css';
import logo from "./GrlLogo.png";


function Contact() {
  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const handlePhoneClick = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  return (
    <>
      <Helmet>
        <title>Contact GRL Logistics | Get in Touch With Our Team</title>
        <meta name="description" content="Contact GRL Logistics for all your logistics needs. Our friendly team is available Mon-Fri from 8am to 5pm. Located in JP Nagar, Bengaluru." />
        <meta name="keywords" content="GRL Logistics, contact, logistics company, Bengaluru, JP Nagar" />
        <meta property="og:title" content="Contact GRL Logistics" />
        <meta property="og:description" content="Get in touch with GRL Logistics for professional logistics services in Bengaluru." />
      </Helmet>

      <div className="contact-wrapper">
        <div className="container contact-section">
          <div className="row g-4 justify-content-center">
            <div className="col-md-6 text-center d-flex flex-column justify-content-center">
              <div className="company-info">
                <div className="circle text-white mb-4">
                <img src={logo} alt="GRL Logistics Logo" className="grllogo" />

                </div>
                <Link to="/" className="read-more-btn">
                  Learn More About Us
                  <FaArrowRight className="ms-2" />
                </Link>
              </div>
            </div>

            <div className="col-md-6 contact-details">
              <h2 className="contact-header mb-4">Get in Touch</h2>
              
              <div className="contact-cards">
                <div className="contact-item" onClick={() => handleEmailClick('grllogistics777@gmail.com')}>
                  <div className="icon-wrapper text-white">
                    <FaEnvelope size={24} />
                  </div>
                  <div className="info">
                    <h5>CHAT TO US</h5>
                    <p>Our friendly team is here to help.</p>
                    <a href="mailto:grllogistics777@gmail.com" className="contact-link">
                      grllogistics777@gmail.com
                    </a>
                  </div>
                </div>

                <div className="contact-item">
                  <div className="icon-wrapper text-white">
                    <FaMapMarkerAlt size={24} />
                  </div>
                  <div className="info">
                    <h5>OFFICE LOCATION</h5>
                    <address>
                      #26, JP Nagar 1st block, 9th Phase,<br/>
                      Near HM World City,<br/>
                      Bengaluru - 560 062
                    </address>
                  </div>
                </div>

                <div className="contact-item" onClick={() => handlePhoneClick('+919113641678')}>
                  <div className="icon-wrapper text-white">
                    <FaPhone size={24} />
                  </div>
                  <div className="info">
                    <h5>PHONE</h5>
                    <p>Mon-Sun  24x7</p>
                    <a href="tel:+919113641678" className="contact-link">+91 9113641678</a>
                    <a href="tel:+916362447084" className="contact-link">+91 6362447084</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;