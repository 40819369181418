// HomePage.js
import React from 'react'
import VideoPlayer from './Video'
import { Mainabout, Review, Services } from './Components'

function HomePage() {
  return (
    <div className="homepage">
      <VideoPlayer/>
      <Mainabout/>
      <Services/>
      <Review/>
    </div>
  )
}

export default HomePage