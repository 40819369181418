// Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "./GrlLogo.png";
import './Header.css';

const Mainheader = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
    // Prevent body scroll when menu is open
    document.body.style.overflow = !isNavCollapsed ? 'auto' : 'hidden';
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!isNavCollapsed && !event.target.closest('.navbar')) {
        setIsNavCollapsed(true);
        document.body.style.overflow = 'auto';
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isNavCollapsed]);

  return (
    <nav className={`navbar navbar-expand-lg fixed-top ${isScrolled ? 'scrolled' : ''}`} role="navigation">
      <div className="container">
        <div className="navbar-brand-container">
          <Link to="/" aria-label="Go to homepage">
            <img src={logo} alt="GRL Logistics Logo" className="navbar-logo" />
            <span className="navbar-brand">
              <span className="brand-text">GRL LOGISTICS</span>
            </span>
          </Link>
        </div>

        <button
          className="navbar-toggler custom-toggler"
          type="button"
          onClick={handleNavCollapse}
          aria-expanded={!isNavCollapsed}
          aria-label="Toggle navigation menu"
          aria-controls="navbarNav"
        >
          <div className={`hamburger ${!isNavCollapsed ? 'active' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>

        <div 
          className={`navbar-collapse ${isNavCollapsed ? '' : 'show'}`} 
          id="navbarNav"
          aria-hidden={isNavCollapsed}
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/about">About Us</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services">Services</Link>
            </li>
            <li className="nav-item">
              <Link to="/contactus" className="nav-link contact-link">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Mainheader;