import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer id="footer" className="bg-dark text-light py-4 py-md-5">
      <div className="container">
        <div className="row g-4">
          {/* Company Info Section */}
          <div className="col-12 col-md-6 col-lg-3">
            <h4 className="border-bottom border-light pb-2 mb-3 ">GRL Logistics</h4>
            <ul className="list-unstyled mb-0">
              <li className="text-secondary small">
                #26, JP Nagar 1st block, 9th Phase,
              </li>
              <li className="text-secondary small">Near HM World City,</li>
              <li className="text-secondary small mb-3">Bengaluru -560 062</li>
              <div className="mt-3">
                <div className="d-flex align-items-center mb-2">
                  <span className="fw-medium text-light">Srinivas V:</span>
                  <a href="tel:+919113641678" className="text-secondary small text-decoration-none ms-2 hover-light">
                    +91 9113641678
                  </a>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="fw-medium text-light">Lokesh S:</span>
                  <a href="tel:+916362447084" className="text-secondary small text-decoration-none ms-2 hover-light">
                    +91 6362447084
                  </a>
                </div>
                <a href="mailto:grllogistics777@gmail.com" className="text-secondary small text-decoration-none hover-light d-block text-center">
                  grllogistics777@gmail.com
                </a>
              </div>
            </ul>
          </div>

          {/* Services Section */}
          <div className="col-12 col-md-6 col-lg-3">
            <h4 className="border-bottom border-light pb-2 mb-3 ">Services Provided</h4>
            <ul className="list-unstyled row row-cols-2 g-2 mb-2">
              <li className="col">
                <span className="text-light text-decoration-none d-block p-1 rounded hover-bg-secondary transition">
                  Hyderabad
                </span>
              </li>
              <li className="col">
                <span className="text-light text-decoration-none d-block p-1 rounded hover-bg-secondary transition">
                  Vizag
                </span>
              </li>
              <li className="col">
                <span className="text-light text-decoration-none d-block p-1 rounded hover-bg-secondary transition">
                  Vijayawada
                </span>
              </li>
              <li className="col">
                <span className="text-light text-decoration-none d-block p-1 rounded hover-bg-secondary transition">
                  Kolkata
                </span>
              </li>
              <li className="col">
                <span className="text-light text-decoration-none d-block p-1 rounded hover-bg-secondary transition">
                  Ahmedabad
                </span>
              </li>
              <li className="col">
                <span className="text-light text-decoration-none d-block p-1 rounded hover-bg-secondary transition">
                  Pune
                </span>
              </li>
              <li className="col">
                <span className="text-light text-decoration-none d-block p-1 rounded hover-bg-secondary transition">
                  Mumbai
                </span>
              </li>
              <li className="col">
                <span className="text-light text-decoration-none d-block p-1 rounded hover-bg-secondary transition">
                  Chennai
                </span>
              </li>
            </ul>
            <p className="text-secondary small mb-0 ">PAN INDIA...</p>
          </div>

          
          <div className="col-12 col-md-6 col-lg-3">
            <h4 className="border-bottom border-light pb-2 mb-3 ">Quick Links</h4>
            <ul className="list-unstyled mb-0">
              <li className="mb-2">
                <Link to="/About" className="text-light text-decoration-none d-block p-1 rounded hover-bg-secondary transition">
                  Know about us
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/Services" className="text-light text-decoration-none d-block p-1 rounded hover-bg-secondary transition">
                  Services
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/contactus" className="text-light text-decoration-none d-block p-1 rounded hover-bg-secondary transition">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          {/* Map Section */}
          <div className="col-12 col-md-6 col-lg-3">
            <h4 className="border-bottom border-light pb-2 mb-3 ">Our Location</h4>
            <div className="ratio ratio-4x3 rounded overflow-hidden border border-secondary">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.511816784675!2d77.5691287!3d12.8720731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15a720287ded%3A0x498b3d8c8f48c93e!2sGRL%20Logistics!5e0!3m2!1sen!2sin!4v1690999381615!5m2!1sen!2sin"
                allowFullScreen=""
                loading="lazy"
                className="w-100 h-100"
                title="GRL Logistics Location"
              ></iframe>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="mt-4 text-center text-secondary small border-top border-secondary pt-3">
          &copy; GRL Logistics. All rights reserved. 2024
        </div>
      </div>

      <style>
        {`
          .hover-light:hover {
            color: #fff !important;
            transition: color 0.3s ease;
          }
          
          .hover-bg-secondary:hover {
            background-color: rgba(255, 255, 255, 0.1);
            transition: background-color 0.3s ease;
          }
          
          .transition {
            transition: all 0.3s ease;
          }

          #footer h4 {
            font-weight: bold;
            letter-spacing: 0.5px;
          }

          #footer .contact-info {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          #footer a {
            color: #6c757d;
          }

          #footer a:hover {
            color: #fff;
          }
        `}
      </style>
    </footer>
  );
};

export default Footer;