import React, { useEffect, useState } from 'react';
import { BiCar, BiClipboard, BiHomeAlt } from 'react-icons/bi';
import founderImage from './Images/founder.jpg';

// Styles object
const styles = {
  container: {
    fontFamily: 'Poppins, sans-serif',
    maxWidth: '1200px',
    margin: '40px auto',
    padding: '0 24px',
    color: '#2c3e50',
    lineHeight: '1.6',
  },
  paragraph: {
    color: '#555',
    fontSize: '1.1rem',
    margin: '0 0 1.5rem 0',
  },
  subheading: {
    fontSize: '1.75rem',
    fontWeight: '600',
    color: '#2980b9',
    margin: '2rem 0 1rem 0',
    paddingLeft: '0.5rem',
  },
  card: {
    transition: 'all 0.3s ease',
  },
  testimonialContainer: {
    padding: '20px 30px',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '950px',
    margin: '0 auto',
    transition: 'all 0.3s ease',
  },
  navigationButton: {
    color: '#3498db',
    border: '2px solid #3498db',
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.2em',
    background: 'transparent',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },
};

// AnimationProvider component to handle animations
const AnimationProvider = ({ children }) => {
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes fadeIn {
        from { opacity: 0; transform: translateY(20px); }
        to { opacity: 1; transform: translateY(0); }
      }
      @keyframes slideIn {
        from { opacity: 0; transform: translateX(-20px); }
        to { opacity: 1; transform: translateX(0); }
      }
      .animate-fade-in {
        opacity: 0;
        animation: fadeIn 0.8s ease-out forwards;
      }
      .animate-slide-in {
        opacity: 0;
        animation: slideIn 0.8s ease-out forwards;
      }
      .delay-1 { animation-delay: 0.2s; }
      .delay-2 { animation-delay: 0.4s; }
      .delay-3 { animation-delay: 0.6s; }
      .hover-lift {
        transition: all 0.3s ease;
      }
      .hover-lift:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return <>{children}</>;
};

// MainAbout Component
const Mainabout = () => (
  <AnimationProvider>
    <div style={styles.container}>
      <article className="animate-fade-in">
        <section>
          <p style={styles.paragraph} className="delay-1">
            Founded in <span style={{ color: '#007bff', fontWeight: '600' }}>2020</span> by Srinivas V, GRL Logistics has rapidly grown into a trusted name in the logistics industry, serving over 1000+ clients and collaborating with 100+ partner companies across India.
          </p>
          <p style={styles.paragraph} className="delay-2">
            At GRL Logistics, we pride ourselves on delivering exceptional service quality by combining technology-driven solutions with a commitment to reliability and efficiency. With a customer-first approach, we ensure timely and safe deliveries, covering a wide range of industries.
          </p>
        </section>
        <section>
          <p style={styles.paragraph} className="delay-3">
            With a strong network and state-of-the-art operations, GRL Logistics is your trusted partner for logistics solutions that drive growth and operational excellence.
          </p>
        </section>
      </article>
    </div>
  </AnimationProvider>
);

// Services Component
const Services = () => (
  <AnimationProvider>
    <section style={styles.container}>
      <h2 style={styles.subheading} className="animate-slide-in">Our Services Include:</h2>
      <div className="d-flex justify-content-around flex-wrap">
        {[
          {
            icon: <BiHomeAlt />,
            text: "Fleet Ownership and Management: Offering a variety of container sizes to suit your transport needs."
          },
          {
            icon: <BiCar />,
            text: "Transport Contracting: Ensuring seamless transportation solutions for businesses across the country."
          },
          {
            icon: <BiClipboard />,
            text: "Commission Agent Services: Facilitating logistics management with expertise and transparency."
          }
        ].map((service, index) => (
          <div key={index} className="card border-0 shadow-sm p-3 text-center animate-fade-in hover-lift delay-1">
            <div style={{ transition: 'all 0.3s ease' }}>
              {React.cloneElement(service.icon, { style: { fontSize: '40px', color: '#007bff' } })}
            </div>
            <p style={styles.paragraph}>{service.text}</p>
          </div>
        ))}
      </div>
    </section>
  </AnimationProvider>
);

// Vision Component
const Vision = () => (
  <AnimationProvider>
    <div className="col-lg-5">
      <div className="card h-100 border-0 shadow-sm hover-lift animate-fade-in delay-1">
        <div className="card-body p-4">
          <div className="d-flex align-items-center mb-4">
            <h3 style={{ color: '#2980b9', fontSize: '1.75rem' }}>Our Vision</h3>
          </div>
          <p style={{ color: '#555', fontSize: '1.1rem' }}>
            To be the leading logistics partner in India, recognized for our commitment to <span className="fw-bold text-primary">excellence</span>, <span className="fw-bold text-primary">innovation</span>, and <span className="fw-bold text-primary">reliability</span> in transportation solutions.
          </p>
        </div>
      </div>
    </div>
  </AnimationProvider>
);

// Mission Component
const Mission = () => (
  <AnimationProvider>
    <div className="col-lg-5">
      <div className="card h-100 border-0 shadow-sm hover-lift animate-fade-in delay-2">
        <div className="card-body p-4">
          <div className="d-flex align-items-center mb-4">
            <h3 style={{ color: '#2980b9', fontSize: '1.75rem' }}>Our Mission</h3>
          </div>
          <ul>
            {[
              'To provide efficient and reliable logistics solutions that meet customer needs.',
              'To foster a culture of innovation, teamwork, and continuous improvement.',
              'To build strong relationships by delivering consistent and timely services.'
            ].map((item, index) => (
              <li key={index} style={{ color: '#555', fontSize: '1.1rem' }}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </AnimationProvider>
);

// Review Component
const Review = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const testimonials = [
    {
      id: 1,
      name: "Likith Raj",
      text: "GRL Logistics has truly been a strategic partner for our business. Their customer-first approach and commitment to timely deliveries have helped us grow. They understand the challenges of our industry and offer scalable solutions that have supported our growth. We've seen a significant improvement in our operational efficiency since we started working with them."
    },
    {
      id: 2,
      name: "Rajesh",
      text: "GRL Logistics has been an invaluable partner for our business. Their fleet management services have helped us streamline our transportation, ensuring timely deliveries every time. With their wide range of container sizes, we were able to choose the perfect fit for our needs. Their professionalism and commitment to excellence truly set them apart in the logistics industry."
    },
    {
      id: 3,
      name: "Sandeep K",
      text: "As a transport contractor, partnering with GRL Logistics has been a game-changer for us. Their efficient and reliable transport solutions have allowed us to expand our operations and meet client demands seamlessly. The team is proactive, and their ability to adapt to our unique requirements is unmatched. I highly recommend their services to anyone looking for dependable logistics support."
    }
  ];

  return (
    <AnimationProvider>
      <div style={styles.container}>
        <h3 style={styles.subheading} className="animate-slide-in">Customer Review</h3>
        <div style={styles.testimonialContainer} className="hover-lift animate-fade-in">
          <div style={{ marginBottom: '20px' }}>
            <h3 style={{ fontSize: '1.25rem', fontWeight: '700', marginBottom: '10px' }}>{testimonials[activeIndex].name}</h3>
            <p style={{ fontSize: '1.1rem', lineHeight: '1.8', color: '#34495e' }}>{testimonials[activeIndex].text}</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <button
              style={styles.navigationButton}
              onClick={() => setActiveIndex(prev => prev === 0 ? testimonials.length - 1 : prev - 1)}
            >←</button>
            <button
              style={styles.navigationButton}
              onClick={() => setActiveIndex(prev => prev === testimonials.length - 1 ? 0 : prev + 1)}
            >→</button>
          </div>
        </div>
      </div>
    </AnimationProvider>
  );
};

// FoundersTalk Component
const FoundersTalk = () => (
  <AnimationProvider>
    <div style={styles.container}>
      <h2 style={styles.subheading} className="animate-slide-in">Founder's Talk</h2>
      <div
        className="animate-fade-in hover-lift"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <img
          src={founderImage}
          alt="."
          style={{
            width: '16%',  
            height: '1%',  
            objectFit: 'cover',  
            borderRadius: '8px'  
          }}
        />
      <p style={{ color: '#555', fontSize: '1.19rem' , paddingLeft:'15px'}}>
        At GRL Logistics, we're driven by a commitment to redefine logistics with reliability and speed.
        Our team constantly innovates to ensure seamless delivery experiences for every client. Thank you
        for trusting us with your logistics needs.
      </p>
    </div>
  </div>
  </AnimationProvider >
);

export { Mainabout, Services, Mission, Vision, Review, FoundersTalk };