import React from 'react';

const WhatsAppButton = () => {
  return (
    <a 
      href="https://wa.me/+916362447084" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={styles.whatsAppButton}
    >
      Chat with us
    </a>
  );
};

const styles = {
  whatsAppButton: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#25D366',
    color: '#fff',
    padding: '10px 15px',
    borderRadius: '50px',
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
  }
};

export default WhatsAppButton;
