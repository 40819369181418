import React from 'react';
import { Routes, Route } from 'react-router-dom';
import  Mainheader  from './Files/Header';
import About from './Files/About';
import Services from './Files/ServicePage';
import HomePage from './Files/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import WhatsAppButton from './Files/WhatsApp';
import Footer from './Files/Footer';
import Contact from './Files/Contact_Us';

function App() {
  return (
    <div>
      <Mainheader />
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contactus" element={<Contact/>}     />
      </Routes>
      <Footer/>
      <WhatsAppButton/>
    </div>
  );
}

export default App;
