import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { FaTruck, FaHandshake } from 'react-icons/fa';
import map from './Images/country.svg';
import './ServicePage.css';

const ServicePage = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const stats = [
    { number: '100+', label: 'Transporters' },
    { number: '15+', label: 'Cities Connected' },
    { number: '100+', label: 'Trusted Customers' }
  ];

  return (
    <>
      <Helmet>
        <title>GRL Logistics Services | Fleet Management & Transport Solutions</title>
        <meta 
          name="description" 
          content="GRL Logistics offers comprehensive fleet management and transport contracting services across India. Available container sizes: 20, 22, 24, and 32 feet."
        />
        <meta 
          name="keywords" 
          content="logistics services, fleet management, transport contracting, container transport, India logistics"
        />
      </Helmet>

      <div className="service-page">
        <div className="service-hero">
          <div className="container">
            <motion.h1 
              className="service-header"
              {...fadeIn}
            >
              Our Services Include
            </motion.h1>
          </div>
        </div>

        <div className="container service-content">
          <motion.div 
            className="service-card"
            {...fadeIn}
            transition={{ delay: 0.2 }}
          >
            <div className="service-icon">
              <FaTruck size={32} />
            </div>
            <div className="service-text">
              <h2 className="service-title">Fleet Ownership and Management</h2>
              <p className="paracontent">
                At GRL Logistics, we take pride in our extensive fleet, offering a range of containers to accommodate various transportation needs.
                <div className="container-sizes">
                  <span>20 feet</span>
                  <span>22 feet</span>
                  <span>24 feet</span>
                  <span>32 feet</span>
                </div>
                Whether you're moving goods across short distances or long hauls, our fleet is equipped to ensure safe and timely deliveries.
              </p>
            </div>
          </motion.div>

          <motion.div 
            className="service-card"
            {...fadeIn}
            transition={{ delay: 0.4 }}
          >
            <div className="service-icon">
              <FaHandshake size={32} />
            </div>
            <div className="service-text">
              <h2 className="service-title">Transport Contracting</h2>
              <p className="paracontent">
                With years of expertise, we specialize in providing end-to-end transportation solutions. We work closely with clients to design transportation plans that align with their supply chain needs.
              </p>
            </div>
          </motion.div>

          <motion.div 
            className="map-cities"
            {...fadeIn}
            transition={{ delay: 0.6 }}
          >
            <div className="country-container">
              <img 
                src={map} 
                alt="GRL Logistics Service Coverage Map of India" 
                className="india-map" 
              />
            </div>
            <div className="network-section">
              <h2 className="network-header">Join our Network</h2>
              <div className="stats-container">
                {stats.map((stat, index) => (
                  <motion.div 
                    key={index}
                    className="stat"
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 0.8 + (index * 0.2) }}
                  >
                    <span className="stat-number">{stat.number}</span>
                    <span className="stat-label">{stat.label}</span>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default ServicePage;