import React from 'react'
import { FoundersTalk, Mainabout, Mission, Services, Vision } from './Components'
import ImageSlider from './ImageSlider'

function About() {
  return (
    <div style={{fontFamily:'Poppins,sans-serif'}}>
      <ImageSlider/>
      <Mainabout/>
      <FoundersTalk/>
      <div className="row g-4 justify-content-center">
      <Vision />
      <Mission />
    </div>
   <br/>
    </div>
  )
}

export default About
