// Video.js
import React from 'react';
import ReactPlayer from 'react-player';
import './video.css';
import demovideo from './Home_Video.mp4';

function VideoPlayer() {
  return (
    <div className="video-wrapper">
      <ReactPlayer
        url={demovideo}
        playing
        loop
        muted
        playsinline
        className="main-video"
        width="100%"
        height="100%"
      />
      <div className="video-overlay">
        <div className="text-content">
          <h1>GRL LOGISTICS</h1>
          <div className="divider"></div>
          <p>Fleet Owners, Transport Contractors, and Commission Agents</p>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
